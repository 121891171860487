.sdiWaitingWrap {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0);
	z-index: 220;
	transition: background-color 0.4s;

	.sdiWaiting {
		position: absolute;
		width: 100%;
		height: 430px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.sdiWaitingCon {
			height: 430px;
			transform: scale(0.5, 0.5);
			color: #fff;
			text-align: center;
			opacity: 0;
			transition: transform 0.3s;

			.imgCon {
				width: 320px;
				height: 150px;
				margin: 0 auto 30px;
				position: relative;
			}

			img.pot {
				width: 167px;
				height: 150px;
				display: block;
				margin: 0 auto;
			}

			.dot {
				width: 18px;
				height: 18px;
				background-color: #FFB600;
				border-radius: 50%;
				position: absolute;
				top: 17px;
				left: 98px;
				animation: jump 1.2s infinite;
			}

			.title {
				font-weight: 600;
				font-size: 18px;
				line-height: 40px;
			}

			.desc {
				font-weight: 450;
				font-size: 16px;
				width: 100%;
				margin: 0 auto;
				padding: 0 20px;
			}

			.closeBtn {
				display: block;
				width: 45px;
				height: 45px;
				border-radius: 50%;
				position: absolute;
				bottom: 0;
				left: calc(50% - 22.5px);
				background-color: #fff;
			}
		}
	}

	&.show {
		background-color: rgba(0, 0, 0, 0.82);

		.sdiWaiting {
			.sdiWaitingCon {
				transform: scale(1, 1);
				opacity: 1;
			}
		}
	}

	@keyframes jump {
		0% {
			transform: translateY(0);
		}

		50% {
			transform: translateY(-45px);
		}

		100% {
			transform: translateY(0);
		}
	}
}