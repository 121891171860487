.ui_select_wrap {
	height: 36px;
	position: relative;
	margin-bottom: 7px;

	.ui_select {
		background-color: #f2f2f2;
		height: 100%;
		display: flex;
		align-items: center;
		padding-left: 16px;
		border-radius: 5px;
		transform: all 0.2s;
		border: 1px solid #f2f2f2;
		outline: none;

		.ui_select_current {
			width: 0;
			flex: 1;
			font-size: 14px;
			font-weight: 600;
			color: #333;
			word-break: break-all;
			overflow: hidden;
			text-overflow: ellipsis;
			height: 24px;
			line-height: 24px;
			white-space: nowrap;
		}

		.ui_select_arrow {
			height: 36px;
			width: 36px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 16px;
				height: 16px;
				transition: transform 0.3s;
			}
		}

		&.active {
			border-color: var(--theme-color);

			.ui_select_arrow {
				img {
					transform: rotate(-180deg);
				}
			}
		}
	}
}

.ui_select_options_wrap {
	position: fixed;
	z-index: 110;

	.ui_select_options {
		border-radius: 6px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
		overflow: auto;
		padding: 6px 0;
		background-color: #fff;
		transition: all 0.42s;

		.ui_select_option {
			height: 34px;
			line-height: 34px;
			padding-left: 16px;
			font-size: 14px;
			cursor: pointer;

			&:hover {
				background-color: #f8f8f8;
			}
		}
	}

	.ui_select_options.bottom::after {
		position: absolute;
		display: block;
		content: " ";
		width: 8px;
		height: 8px;
		background-color: #fff;
		left: 30px;
		top: -4px;
		transform: rotate(45deg);
		border-top: 1px solid rgba(180, 180, 180, 0.2);
		border-left: 1px solid rgba(180, 180, 180, 0.2);
	}

	.ui_select_options.top::after {
		position: absolute;
		display: block;
		content: " ";
		width: 8px;
		height: 8px;
		background-color: #fff;
		left: 30px;
		bottom: -5px;
		transform: rotate(45deg);
		border-bottom: 1px solid rgba(180, 180, 180, 0.2);
		border-right: 1px solid rgba(180, 180, 180, 0.2);
	}
}