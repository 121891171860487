@import "../../constants/global.scss";

.paymentOutter {
    @include floatPanel;
    z-index: 62;
}

.paymentInner {
    @include floatPanelInner;
    z-index: 62;
    min-height: 420px;
    max-height: 80%;
    width: 480px;
    top: 10%;
    left: 50%;
    margin-left: -240px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.closeHeader {
    display: block;
    height: 20px;
}

.paymentHeader {
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
    display: none;
}

.paymentBody {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.backIcon {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
        font-size: 16px;
        margin-left: 22px;
    }
}

.paymentTitle {
    flex: 1;
    text-align: center;
    height: 48px;
    line-height: 48px;
    padding: 0 30px 0 0;
    font-size: 17px;
    font-weight: bold;
}

.paymentList {
    margin: 20px 0 20px 16px;
}

.savedPaymentTitle {
    margin: 20px 0 0 16px;
    height: 17px;
    line-height: 17px;
    font-size: 17px;
    font-weight: bold;
}

.savedPaymentCont {
    flex: 1;
    overflow: auto;
}

.paymentItemBx {
    margin-left: 16px;
    margin-right: 32px;
    padding: 12px 0 12px 0;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .paymentIcon {
        margin-right: 22px;
        font-size: 30px;
    }
}

.paymentType {
    flex: 1;
    height: 36px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.paymentName {
    line-height: 18px;
    font-size: 13px;
}

.selectedPaymentTips {
    display: flex;
    margin-top: 4px;
    line-height: 14px;
    font-size: 12px;
    vertical-align: middle;
    word-wrap: break-word;
    display: none;

    .note {
        color: var(--theme-text-color);
        margin-right: 4px;
    }

    .text {
        font-style: normal;
        color: #333
    }
}

.selectedIcon {
    color: var(--theme-color);
    display: none;
}

.isSelectedPayment {

    .selectedIcon,
    .selectedPaymentTips {
        display: flex;
    }
}

.disablePayment {
    color: #999;
}

.gpayIcon {
    width: 42px;
    height: 22px;
    margin-right: 10px;
}

.paymentFoot {
    margin-bottom: 40px;

    .paymentItemBx {
        margin-left: 32px;
        padding-bottom: 0;
        border-bottom: none;
    }

    .paymentType {
        display: flex;
    }

    .backaddPaymentIcon {
        cursor: pointer;
        font-size: 20px;
    }

    .rotateaddPaymentIcon {
        transform: rotate(90deg)
    }

    .cardIconList {
        display: flex;
        margin-left: 84px;
    }

    .cardIcon {
        width: 26px;
        height: 26px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .mastercard {
        background-image: url(../../images/card/mastercard.png);
    }

    .visa {
        background-image: url(../../images/card/visa.png);
    }

    .jcb {
        background-image: url(../../images/card/jcb.png);
    }

    .dinersclub {
        background-image: url(../../images/card/dinersclub.png);
    }

    .americanexpress {
        background-image: url(../../images/card/americanexpress.png);
    }

    .discover {
        background-image: url(../../images/card/discover.png);
    }

    .unionpay {
        background-image: url(../../images/card/unionpay.png);
    }

    .addPayemntBx {
        padding: 0 24px;
    }

}

@media (max-width:480px) {
    .paymentInner {
        min-height: auto;
        max-height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: auto;
        margin-top: 0;
        margin-left: 0;
        border-radius: 0;
    }

    .closeHeader {
        display: none;
    }

    .paymentHeader {
        display: flex;
    }
}