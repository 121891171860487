.mergeEmailBx {
    .emailContBx {
        padding: 43px 24px 22px;
    }

    .mergeDescription {
        margin-bottom: 32px;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
    }

    .mergeEmailCon {
        flex: 1;
        overflow: auto;
    }

    .phoneExistTip {
        position: relative;
        margin-top: 8px;
        padding-left: 16px;
        font-size: 16px;
        line-height: 22px;

        .phoneNumberFormat {
            font-weight: 600;
        }

        &.phoneExistTip3 {
            padding-top: 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }

        &::before {
            content: " ";
            position: absolute;
            left: 0;
            top: 8px;
            width: 8px;
            height: 8px;
            background: var(--theme-color);
            border-radius: 50%;
        }

    }

    .emailOptionBx {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 0 18px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.15);

        .emailOption {
            display: flex;
            padding: 25px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            font-size: 16px;
            cursor: pointer;
        }

        .emailOption:last-child {
            border-bottom: none;
        }

        .radio {
            margin-top: 2px;
            margin-right: 16px;
            font-size: 20px;
        }

        .label {
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);

            .email {
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .current {
            display: inline-block;
            margin-top: 4px;
            padding: 0 8px;
            width: auto;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            font-weight: 400;
            border-radius: 14px;
            color: var(--theme-color);
            background: var(--theme-opacity-color);
        }
    }

    .btnCon {
        margin-top: 32px;

        .submitBtn {
            margin-top: 16px;
        }
    }
}

@media screen and (min-width: 481px) {
    .mergeEmailBx {
        .emailContBx {
            padding: 0;
        }

        .emailOptionBx {
            .emailOption {
                padding: 25px 0;
            }

            .label {
                width: calc(100% - 36px);

                .current {
                    width: auto;
                }
            }
        }
    }
}