.ui_popup_wrap {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 91;
	background-color: rgba(0, 0, 0, 0);
	transition: all 0.3s;
	cursor: default;

	.ui_popup_bg {
		width: 100%;
		height: 100%;
	}

	.ui_popup {
		z-index: 92;
		background-color: #fff;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		overflow: hidden;
		position: absolute;
		transition: all 0.3s;

		.ui_popup_close_btn {
			position: absolute;
			top: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			width: 32px;
			height: 32px;
			z-index: 93;

			img {
				width: 24px;
				height: 24px;
				transition: all 0.1s;
			}

			i {
				font-size: 32px;
				color: rgba(0, 0, 0, 0.8);
			}

			&:hover {
				i {
					font-size: 34px;
				}
			}

			&.closeBtn {
				right: 24px;
			}

			&.leftArrow {
				left: 24px;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.ui_popup_wrap {
		.ui_popup {
			width: 100%;
			left: 0;
			right: 0;
			bottom: 0;
			transform: translate(0, 100%);
		}

		&.show {
			background-color: rgba(0, 0, 0, 0.6);

			.ui_popup {
				transform: translate(0, 0);
			}
		}

		&.hide {
			background-color: rgba(0, 0, 0, 0);

			.ui_popup {
				transform: translate(0, 100%);
			}
		}

		&.mobileFullScreen {
			&>.ui_popup {
				border-radius: 0;
				height: 100%;
			}
		}

		&.mobileCenter {
			&>.ui_popup {
				margin: 0 24px;
				width: auto;
				top: 50%;
				bottom: auto;
				border-radius: 10px;
				transform: translate(0, -50%);
			}
		}

		&.mobileLeftArrow {
			.closeBtn {
				display: none;
			}
		}
	}
}

@media screen and (min-width: 481px) {
	.ui_popup_wrap {
		&.show {
			background-color: rgba(0, 0, 0, 0.5);
		}

		.ui_popup {
			min-width: 342px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			border-radius: 10px;
			overflow: hidden;

			.pcBigSize {
				top: 15%;
				bottom: 15%;
				right: 10%;
				left: 10%;
				transform: translate(0, 0);
			}
		}

		&.show {
			.ui_popup {
				transform: translate(-50%, -50%);
				opacity: 1;
			}
		}

		&.hide {
			opacity: 0;
		}

		&.mobileLeftArrow {
			.leftArrow {
				display: none;
			}
		}
	}
}

@media screen and (min-width: 801px) {
	.ui_popup_wrap {
		.ui_popup {
			&.pcBigSize {
				width: 800px;
				top: 15%;
				bottom: 15%;
				left: calc(50% - 400px);
				transform: translate(0, 0);
			}
		}
	}
}