.timeErrorWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99999;

    .timeErrorCon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 680px;
        background-color: #fff;
        border-radius: 12px;
        padding: 18px 18px 24px;
        text-align: center;

        .tipImg {
            margin-bottom: 10px;
            width: 100px;
            height: 100px;
        }

        .title {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 16px;
            color: #faad14;
        }

        .desc {
            font-size: 16px;
            color: #000;
            margin-bottom: 10px;
        }

        .closeBtn {
            width: 200px;
            height: 34px;
            line-height: 34px;
            margin: 25px auto 0;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
            border: 1px solid #409EFF;
            cursor: pointer;
            font-size: 16px;
            border-radius: 6px;
            color: #409EFF;
            background-color: #fff;
        }

        .closeIcon {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            opacity: 0.7;
        }
    }
}