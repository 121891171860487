.shoppingCartPanel {
	max-height: calc(100% - 80px);
	min-height: 100px;
	display: flex;
	flex-direction: column;

	.shoppingCartHeader {
		display: flex;
		align-items: center;
		height: 40px;
		padding-bottom: 6px;
		padding-left: 6px;
		border-bottom: 1px solid #F2F2F2;

		.svgicon {
			width: 26px;
			height: 26px;
			margin-right: 6px;
		}

		.shoppingCartTitle {
			font-size: 20px;
			font-weight: 700;
			color: #333;
			flex: 1;
			display: flex;
			align-items: center;
		}

		.closeBtn {
			display: none;
		}
	}

	.shoppingCartItems {
		flex: 1;
		overflow: auto;
		padding-bottom: 10px;
		padding: 10px 10px 0;

		.shoppingCartItem {
			display: flex;
			margin-bottom: 10px;
			background-color: #fff;
			border-radius: 10px;
			box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.04);
			padding: 8px;
			transition: box-shadow 0.2s;
			min-height: 66px;

			&:hover {
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
			}

			.itemImg {
				width: 80px;
				height: 80px;
				border-radius: 10px;
				margin: 0 8px 0 0;
				border: 2px solid #F7F7F7;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			.itemDefaultImg {
				background-color: #F7F7F7;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.itemGap {
				width: 4px;
			}

			.itemInfo {
				flex: 1;
				display: flex;
				flex-direction: column;

				.itemName {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					color: #222234;
					font-size: 16px;
					font-weight: 600;
					margin-top: 3px;
					margin-bottom: 3px;
					line-height: 18px;
				}

				.itemOption {
					color: #828282;
					font-size: 14px;
					line-height: 17px;
					flex: 1;
				}

				.itemPriceQuantity {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.itemPrice {
						display: flex;
						color: #4F4F4F;
						font-weight: 700;
						font-size: 16px;

						.strikethroughStyle {
							font-size: 12px;
							line-height: 22px;
							color: #828282;
							font-weight: 400;
							text-decoration: line-through;
							margin-right: 8px;
						}

						.beforeDiscountPrice {
							text-decoration: line-through;
							padding-right: 5px;
							font-weight: normal;
							color: var(--theme-color);
						}

						.spendPoint {
							font-size: 12px;
							line-height: 22px;
							margin-left: 2px;
						}
					}

					.itemQuantity {
						display: flex;
						align-items: center;
						justify-content: space-between;
						border: 1.5px solid rgba(150, 39, 47, 0.1);
						border-radius: 20px;
						min-width: 80px;
						height: 27px;
						color: #222234;

						&.disabled {
							cursor: not-allowed;
							opacity: 0.6;
						}

						.itemQuantityOption {
							cursor: pointer;
							display: flex;
							justify-content: center;
							width: 28px;

							&.disabled {
								cursor: not-allowed;
							}
						}

						.itemQuantityNum {
							font-weight: 700;
							font-size: 15px;
						}

						.itemQuantityReduce .icon {
							font-size: 10px;
						}

						.itemQuantityPlus {
							.icon {
								font-size: 20px;
								color: var(--theme-color);
							}

							&.disabled {
								cursor: not-allowed;

								.icon {
									color: #e4e4e4;
								}
							}
						}
					}
				}
			}
		}
	}

	.cartNote {
		padding-top: 6px;
		width: calc(100% - 30px);
		margin: 0 auto;
		border-top: 1px solid #F2F2F2;
	}
}

.emptyCart {
	text-align: center;
	font-size: 16px;
	color: #bdbdbd;
	line-height: 26px;
	padding-top: 30px;
	padding-bottom: 20px;

	img {
		width: 120px;
		height: 120px;
		margin-bottom: 30px;
	}
}

.shoppingCartBtn {
	.checkoutBtn {
		width: 335px;
		height: 50px;
		line-height: 50px;
		background-color: var(--theme-color);
		border-radius: 10px;
		margin: 5px auto 0;
		box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
		transition: all 0.2s;
		cursor: pointer;
		color: var(--color-btn-text);
		font-weight: 600;
		font-size: 18px;
		position: relative;

		.bagIconBox {
			position: absolute;
			height: 50px;
			width: 50px;
			left: 10px;
			top: 0;
			padding-top: 1px;
			text-align: center;

			i.icon {
				font-size: 40px;
				color: var(--color-btn-text);
			}

			.goodsQty {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				line-height: 59px;
				text-align: center;
				color: var(--theme-text-color);
				font-size: 15px;
				font-weight: 600;
			}

			&.bagBoxShake {
				animation: shake 0.2s;
			}

			&.bagBoxShrink {
				animation: shrink 0.2s;
			}

			@keyframes shake {
				0% {
					transform: rotate(0);
				}

				25% {
					transform: rotate(15deg);
				}

				50% {
					transform: rotate(0deg);
				}

				75% {
					transform: rotate(-15deg);
				}

				100% {
					transform: scale(0deg);
				}
			}

			@keyframes shrink {
				0% {
					transform: scale(1, 1);
				}

				35% {
					transform: scale(0.75, 0.75);
				}

				100% {
					transform: scale(1, 1);
				}
			}
		}

		.priceInfo {
			padding-left: 10px;
			display: flex;
			align-items: center;
			justify-content: center;

			.btnName {
				overflow: hidden;
				height: 32px;
				text-align: right;

				.scrollBtnName {
					height: 64px;
					transition: transform 0.3s;

					div {
						height: 32px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						line-height: 24px;
					}
				}

				&.toggle {
					.scrollBtnName {
						transform: translate3d(0, -32px, 0);
					}
				}

				.normalBtnName {
					line-height: 32px;
				}
			}

			>span {
				padding: 0 8px;
			}
		}

		&.disabled {
			background-color: #ccc;
			box-shadow: none;
			cursor: default;
			cursor: not-allowed;

			.goodsQty {
				color: #ccc;
			}
		}

		&:hover {
			box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
		}
	}
}

@media screen and (max-width: 768px) {
	.shoppingCartPanelOut {
		position: fixed;
		z-index: 50;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0);
		transition: background-color 0.2s;

		.shoppingCartPanel {
			background-color: #fff;
			padding: 0 0 70px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			transform: translate3d(0, 100%, 0);
			transition: transform 0.2s;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;

			.shoppingCartHeader {
				height: 58px;
				padding: 0px;
				width: calc(100% - 30px);
				margin: 0 auto;

				.shoppingCartTitle {
					padding-left: 0px;
				}

				.closeBtn {
					width: 30px;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					padding-right: 6px;
					color: #666;

					.icon {
						font-size: 14px;
					}
				}
			}

			.shoppingCartItems {
				padding: 5px 0 0;

				.shoppingCartItem {
					width: calc(100% - 30px);
					margin: 0 auto 10px;
				}
			}
		}

		&.show {
			background-color: rgba(0, 0, 0, 0.7);

			.shoppingCartPanel {
				-webkit-transform: none;
				transform: none;
				-webkit-transform: unset;
				transform: unset;
			}
		}
	}

	.shoppingCartBtn {
		position: fixed;
		left: 0;
		bottom: 0;
		right: 0;
		height: 66px;
		z-index: 61;

		.checkoutBtn {
			height: 46px;
			line-height: 46px;
			width: calc(100% - 30px);

			.bagIconBox {
				width: 46px;
				height: 46px;

				i.icon {
					font-size: 36px;
				}

				.goodsQty {
					line-height: 54px;
				}
			}
		}
	}
}