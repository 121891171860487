.tableInfo {
	border-radius: 10px;
	background-color: #fff;
	padding: 12px 10px 14px;
	font-size: 14px;
	margin-bottom: 16px;

	.top {
		display: flex;
		align-items: center;
		height: 30px;
		padding-left: 7px;

		img.icon {
			width: 24px;
			height: 24px;
			margin-top: 2px;
		}

		.tableName {
			color: #000;
			font-weight: 600;
			padding-left: 8px;
		}

		.gap {
			padding: 0 5px;
			color: #c4c4c4;
		}

		.orderStatus {
			color: #69C33F;
			font-weight: 600;
		}
	}

	.bottom {
		line-height: 16px;
		color: #828282;
		font-size: 12px;
		padding-left: 36px;
	}
}