@import "../../constants/orderItem.scss";

.orderDetail {
	padding: 16px;

	.splitTipCon {
		background-color: #fff;
		border-radius: 10px;
		padding: 10px 12px;
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 28px;
			height: 28px;
			margin-right: 10px;
		}

		div {
			font-size: 14px;
			line-height: 20px;
		}
	}

	.topStatusOut {
		transition: height 0.2s linear;
		margin-bottom: 16px;
	}

	.topStatus {
		background-color: #fff;
		border-radius: 10px;
		padding: 10px 16px;
		text-align: center;

		.title {
			font-size: 15px;
			font-weight: bold;
			line-height: 30px;
		}

		.desc {
			font-size: 12px;
			color: #000;
			line-height: 16px;
			padding-bottom: 4px;
		}

		.progress {
			width: 237px;
			height: 24px;
			position: relative;
			margin: 5px auto;

			.progressLine {
				width: 100%;
				height: 100%;
			}

			.progressCircle {
				position: absolute;
				z-index: 2;
				top: 0;
				left: 107px;
			}
		}

		.progressCircle {
			width: 24px;
			height: 24px;
			animation: circle 0.8s linear infinite;
		}

		.deliveryProgress {
			display: flex;
			align-items: center;
			max-width: 420px;
			margin: 0 auto;
			height: 54px;

			.icon {
				width: 22px;
				height: 22px;
				border-radius: 50%;
				background-color: #E0E0E0;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;

				i {
					font-size: 22px;
				}

				&.active {
					background-color: #FFB600;
				}
			}

			.line1 {
				flex: 1;
				margin-left: 5px;
				height: 2px;
				background-color: #E0E0E0;
				border-top-left-radius: 2px;
				border-bottom-left-radius: 2px;

				&.active {
					background-color: #FFB600;
				}
			}

			.line2 {
				flex: 1;
				margin-right: 5px;
				height: 2px;
				border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;
				background-color: #E0E0E0;

				&.active {
					background-color: #FFB600;
				}
			}
		}
	}

	.orderJoinFree {
		display: flex;
		align-items: flex-end;
		background: linear-gradient(90deg, rgba(255, 226, 89, 0.1) 0%, rgba(255, 167, 81, 0.1) 100%), #FFFFFF;
		;
		border-radius: 10px;
		padding: 10px 16px;
		margin-bottom: 16px;

		.orderJoinEarnPts {
			flex: 1;
			font-size: 14px;
			line-height: 21px;
			color: #120136;

			span {
				font-weight: 600;
				padding-left: 2px;
				color: #120136;

				&::before {
					font-family: 'iconfont';
					content: "\e637";
					font-size: 16px;
					color: var(--theme-color);
					margin-right: 2px;
				}
			}
		}

		.joinFree {
			width: 70px;
			height: 22px;
			line-height: 22px;
			background: var(--theme-color);
			border-radius: 40px;
			font-size: 12px;
			font-weight: 600;
			text-align: center;
			color: #120136;
			letter-spacing: -0.02em;
			margin-left: 8px;
		}
	}

	.sdiStatus {
		background-color: #fff;
		border-radius: 10px;
		text-align: center;
		margin-bottom: 16px;
		padding: 8px;

		.title {
			font-size: 15px;
			font-weight: bold;
			line-height: 36px;
		}

		img {
			width: 47px;
			height: 47px;
		}

		.backPayBtn {
			width: 140px;
			height: 36px;
			line-height: 36px;
			text-align: center;
			background-color: var(--theme-color);
			color: #fff;
			text-align: center;
			border-radius: 18px;
			margin: 10px auto 5px;
			font-size: 16px;
		}
	}

	.sections {
		.smallLoading {
			text-align: center;
			padding-top: 6px;

			img {
				width: 18px;
				height: 18px;
				animation: circle 0.8s linear infinite;
				opacity: 0.5;
			}
		}

		.section {
			border-radius: 10px;
			background-color: #fff;
			margin-bottom: 16px;

			.sectionItem {
				position: relative;
				display: flex;
				padding: 16px 16px 8px;
				flex-wrap: wrap;

				&::after {
					content: " ";
					position: absolute;
					height: 1px;
					left: 40px;
					right: 40px;
					bottom: 0;
					border-top: 1px solid #E8EAED;
				}

				&:last-child::after {
					border: none;
				}

				.sectionIcon,
				svg {
					width: 30px;
					height: 30px;
					margin-right: 12px;
					margin-top: -2px;
				}

				svg {
					width: 24px;
					height: 24px;
					margin-left: 5px;
				}

				.sectionInfo {
					flex: 1;
					overflow: hidden;

					.title {
						font-size: 14px;
						color: #000;
						font-weight: 500;
						line-height: 20px;
						margin-bottom: 3px;
						display: flex;

						.main {
							flex: 1;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.more {
							padding-right: 10px;

							img {
								width: 20px;
								height: 20px;
								vertical-align: text-bottom;
							}
						}

						.arrow {
							width: 20px;
							height: 20px;
							opacity: 0.4;
							transition: transform 0.3s;
						}
					}

					.desc {
						font-size: 12px;
						color: #828282;
						line-height: 18px;
						padding-bottom: 4px;

						.link {
							text-decoration: underline;
						}

						.transLine {
							display: flex;
							font-size: 13px;
							color: #666;
							line-height: 24px;
							padding-right: 10px;
							padding-left: 2px;
							justify-content: space-between;
						}
					}

					.orderEarnPoints {
						display: flex;
						align-items: center;
						font-weight: 400;
						font-size: 12px;
						line-height: 16px;
						color: #828282;

						span {
							padding-left: 2px;
							color: #120136;

							&::before {
								font-family: 'iconfont';
								content: "\e637";
								font-size: 16px;
								color: var(--theme-color);
								margin-right: 2px;
							}
						}

						.earnStatus {
							margin-left: 2px;
						}

						.arrowRight {
							color: #120136;
						}
					}
				}

				.deliveryMan {
					position: relative;

					.deliveryManName {
						.phone {
							color: #005AD6;
							cursor: pointer;
							text-decoration: underline;
							margin-left: 12px;
						}
					}

					.deliveryManPhone {
						position: absolute;
						right: 26px;
						bottom: 0;

						img {
							width: 28px;
							height: 28px;
						}
					}
				}

				.thirdPartyId {
					height: 22px;
					display: flex;
					align-items: center;
					font-size: 12px;
					color: #666;

					img {
						width: 15px;
						margin-right: 2px;
						border-radius: 3px;
					}
				}

				.orderSummaryCon {
					width: 100%;
					transition: all 0.3s;
					height: 0;
					overflow: hidden;
				}

				&.open {
					.arrow {
						transform: rotate(90deg);
					}

					.orderSummaryCon {
						height: auto;
						margin-top: 10px;
					}
				}
			}

			&.sectionRate {
				padding: 0;

				.sectionItem {
					padding: 0;
				}
			}
		}
	}

	.orderSummary {
		.orderPrice {
			position: relative;
			padding-top: 7px;

			&::before {
				content: " ";
				position: absolute;
				height: 1px;
				left: 24px;
				right: 24px;
				top: 0;
				border-top: 1px solid #E8EAED;
			}

			.priceLine {
				display: flex;
				justify-content: flex-end;
				color: #828282;
				font-size: 12px;
				line-height: 22px;

				span.colon {
					padding-right: 5px;
				}

				.iconInfo {
					font-size: 14px;
					padding: 0 2px;
				}

				&.total {
					font-size: 16px;
					font-weight: 600;
					color: #000;
					line-height: 28px;
				}

				.redeemStatus,
				.earnStatus {
					color: #828282;
					margin-left: 2px;
				}
			}

			.summaryEarnPoints {
				display: flex;
				justify-content: flex-end;
				font-weight: 600;
				font-size: 16px;
				line-height: 22px;
				letter-spacing: -0.02em;
				color: #120136;
				margin-top: 8px;

				span {
					color: var(--theme-color);
				}

				.subtract {
					font-size: 18px;
					color: var(--theme-color);
					margin-right: 6px;
				}

				.earnLabel {
					text-align: right;

					div {
						display: inline-block;
					}
				}

				.earnStatus {
					font-size: 14px;
					color: #828282;
					font-weight: 500;
				}
			}
		}
	}

	.wait {
		color: var(--warn-color)  !important;
	}

	.success {
		color: var(--success-color)  !important;
	}

	.error {
		color: var(--error-color)  !important;
	}

	.mainColor {
		color: var(--theme-text-color)  !important;
	}

	.link {
		color: #005AD6;
		cursor: pointer;
	}

	.reorderBtn {
		width: 100%;
		background-color: var(--theme-color);
		color: var(--color-btn-text);
		text-align: center;
		font-size: 16px;
		font-weight: bold;
		height: 56px;
		line-height: 56px;
		border-radius: 8px;
		margin: 16px auto 10px;
		cursor: pointer;
	}
}

@media screen and (min-width:769px) {
	.orderDetail {

		.topStatus {
			width: 100%;

			.desc {
				font-size: 14px;
				line-height: 30px;
			}
		}

		.mainCon {
			display: flex;

			.leftInfos {
				flex: 1;
			}
		}

		.sections {
			background-color: #fff;
			border-radius: 10px;
			padding-bottom: 12px;

			.section {
				max-width: 92%;
				margin: 0 auto;

				.sectionItem {

					.sectionIcon,
					svg {
						margin-top: 4px;
					}

					.sectionInfo {
						.title {
							line-height: 36px;

							.main,
							.more {
								font-size: 16px;
							}
						}

						.desc {
							font-size: 14px;
							line-height: 20px;
						}
					}

					.deliveryMan {
						.deliveryManPhone {
							right: 0px;

							img {
								width: 39px;
								height: 39px;
							}
						}
					}

					&::after {
						left: 0;
						right: 0;
					}

					&:last-child::after {
						border-top: 1px solid #E8EAED;
					}
				}

				&:last-child .sectionItem:last-child::after {
					border-top: none;
				}

				&.sectionRate {
					padding-bottom: 10px;

					.sectionItem {
						padding-top: 10px;

						&::before {
							content: "";
							position: absolute;
							top: -2px;
							left: 0;
							right: 0;
							height: 2px;
							background-color: #fff;
						}
					}
				}
			}
		}

		.rightSummary {
			background-color: #fff;
			border-radius: 16px;
			padding: 10px 28px 20px;
			margin-left: 20px;
			width: 368px;
			overflow: auto;

			.title {
				font-size: 17px;
				font-weight: 600;
				color: #000;
				line-height: 40px;
				margin-bottom: 12px;
			}

			.orderSummary {
				.orderPrice {
					&::before {
						left: 0px;
						right: 0px;
					}

					.priceLine {
						font-size: 14px;
						line-height: 28px;

					}
				}
			}
		}

		.reorderBtn {
			width: 352px;
			height: 44px;
			line-height: 44px;
			margin-top: 25px;
		}
	}
}

@media screen and (min-width:1200px) {
	.orderDetail {
		.sections {
			.section {
				max-width: 72%;
			}
		}
	}
}

@keyframes circle {
	0% {
		-webkit-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0)
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}