.ui_toast {
    position: fixed;
    z-index: 210;
    border-radius: 27px;
    background: rgba(27, 27, 27, 0.90);
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 32px;
    left: 50%;
    transform: translate(-50%, 0);
    display: none;
    align-items: center;
    justify-content: center;

    &.animate {
        transition: all 0.4s;
    }

    &.center {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.top {
        top: -80px;

        &.show {
            top: 60px;
        }
    }

    &.bottom {
        bottom: -80px;

        &.show {
            bottom: 60px;
        }
    }

    &.white {
        background-color: rgba(5, 4, 4, 0.95);
        color: #000;
    }

    .icon {
        font-size: 16px;
        margin-right: 7px;
    }

    .msg {
        font-size: 14px;
        font-weight: 700;
    }

    &.withIcon {
        .msg {
            font-weight: normal;
        }
    }

    &.show {
        display: flex;
    }
}

.ui_toast_temp {
    opacity: 0;
    position: absolute;
    font-size: 15px;
    line-height: 22px;
    padding: 14px 32px;

    .icon {
        font-size: 16px;
        margin-right: 7px;
    }

    .msg {
        font-size: 14px;
        font-weight: 700;
    }
}

@media screen and (min-width: 768px) {
    .ui_toast {
        &.bottom {
            bottom: -80px;

            &.show {
                bottom: 78px;
            }
        }

        &.top {
            top: -80px;

            &.show {
                top: 78px;
            }
        }

        .msg {
            letter-spacing: 0.3px;
        }
    }

    .ui_toast_temp {
        .msg {
            letter-spacing: 0.3px;
        }
    }
}