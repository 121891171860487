.utensilsCont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.utensilsLabel {
    width: calc(50% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 40px;
    line-height: 15px;
    background: #f3f3f3;
    margin-bottom: 12px;
    cursor: pointer;
}

.utensilsLabel:nth-child(1) {
    margin-right: 10px;
}

.fullWidth {
    width: 100%;
}

.haveRequest {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.check {
    display: inline-block;
    width: 18px;
    height: 18px;
    color: #fff;
    background: #000;
    background: url(../../images/checkbox1.png) center center no-repeat;
    background-size: 100%;
}

.checked {
    background: url(../../images/checkbox2.png) center center no-repeat;
    background-size: 100%;
}

.utensilsNote {
    font-size: 12px;
    width: 100%;
    line-height: 18px;
    padding-bottom: 12px;

    span {
        color: var(--theme-text-color);
    }
}

@media screen and (max-width: 768px) {
    .utensilsLabel {
        width: calc(50% - 36px);
    }
}

@media screen and (min-width:769px) {
    .utensilsNote {
        margin-top: -12px;
        min-height: 18px;
    }
}