.rewardSelectComponent {
    display: flex;
    align-items: center;

    .rewardSelectCard {
        flex: 1;
        padding-right: 4px;
        display: flex;
        align-items: center;

        .mainTitle {
            font-size: 17px;
            font-weight: 600;
            line-height: 28px;
        }

        .seeAvailable {
            color: #000;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.64px;
            flex: 1;
            cursor: pointer;
            text-align: right;
        }

        .choosedInfo {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            padding-right: 6px;
            cursor: pointer;

            span {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.32px;
                padding: 4px 12px;
                border-radius: 16px;
                background-color: #FFE9B3;
            }
        }
    }

    .arrow {
        cursor: pointer;
    }

}

@media screen and (max-width: 768px) {
    .rewardSelectComponent {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

@media screen and (max-width: 1080px) {
    .rewardSelectComponent {
        .rewardSelectCard {
            display: block;

            .seeAvailable {
                display: none;
            }

            .choosedInfo {
                justify-content: flex-start;
                padding-top: 6px;
            }
        }
    }
}

.rewardSelectDialog {
    background-color: #fff;
    color: #000;

    .rewardSelectDialogHeader {
        padding-top: 30px;
        position: relative;
        text-align: center;

        i {
            position: absolute;
            left: 20px;
            font-size: 28px;
            cursor: pointer;
        }

        span {
            color: rgba(0, 0, 0, 0.80);
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: -0.12px;
        }
    }

    .rewardSelectDialogBody {
        padding: 32px 32px 10px;
        min-height: 300px;
        max-height: calc(80vh);
        overflow: auto;

        .rewardCardOut {
            margin-bottom: 12px;
        }

        .rewardCard {
            border-radius: 10px;
            border: 0.5px solid #D1D1D1;
            overflow: hidden;

            .top {
                display: flex;
                padding: 10px 10px 8px 20px;
                cursor: pointer;

                .left {
                    flex: 1;

                    .costType {
                        font-size: 12px;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        line-height: 15px;
                        margin-bottom: 4px;

                        svg {
                            margin-left: 3px;
                        }

                        span.num {
                            padding: 0 3px;
                        }
                    }

                    .nameAndExpire {
                        height: 38px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .name {
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: -0.32px;
                        }

                        .expireTime {
                            color: #5E5E5E;
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 12px;
                            margin-top: 4px;

                            span {
                                color: #E5A400;
                                font-weight: 700;
                                padding: 0 2px;
                            }
                        }
                    }
                }

                .applyBtn {
                    padding: 8px 20px;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 15px;
                    border-radius: 20px;
                    background: #F0F0F0;
                    transition: all 0.3s;

                    &.removeBtn {
                        background: #FFB600;
                    }
                }
            }

            .detail {
                background-color: rgba(0, 0, 0, 0.06);
                transition: height 0.3s;
                position: relative;
                height: 34px;
                padding: 7px 10px 7px 20px;

                .detailToggleBtn {
                    position: absolute;
                    z-index: 94;
                    top: 7px;
                    right: 20px;
                    height: 20px;
                    width: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    cursor: pointer;

                    img {
                        width: 10px;
                        margin-left: 5px;
                        transition: transform 0.3s;

                        &.expand {
                            transform: rotate(180deg);
                        }
                    }
                }

                .detailSimple {
                    display: flex;
                    align-items: center;
                    color: #5E5E5E;
                    font-size: 10px;
                    line-height: 20px;
                    height: 20px;

                    svg {
                        margin-right: 5px;
                    }
                }

                .detailCon {
                    display: none;
                    padding: 3px 32px 12px 0;
                    color: #5E5E5E;
                    font-size: 10px;
                    line-height: 15px;

                    .title {
                        font-size: 12px;
                    }

                    .option {
                        position: relative;
                        padding-left: 10px;

                        &::before {
                            position: absolute;
                            content: " ";
                            width: 4px;
                            height: 4px;
                            border-radius: 4px;
                            background-color: #5E5E5E;
                            top: 5px;
                            left: 0px;
                        }

                        p {
                            margin: 0 0 4px;
                            display: flex;

                            span {
                                padding-left: 5px;
                            }
                        }

                        .option-desc {
                            flex-direction: column;

                            p {
                                display: block;
                            }
                        }
                    }
                }

                &.expand {
                    .detailSimple {
                        display: none;
                    }

                    .detailCon {
                        display: block;
                    }
                }
            }

            &.disabled {
                background-color: #E8E8E8;

                .top {
                    cursor: default;
                }

                .detail .detailHeader {
                    cursor: default;
                }

                &:hover {
                    border-color: #D1D1D1 !important;
                }
            }

            &.selected {
                border-color: #FFB600;
                box-shadow: 0 0 0 1px #FFB600;

                &:hover {
                    border-color: #FFB600 !important;
                }
            }

        }
    }
}

@media screen and (min-width: 480px) {
    .rewardSelectDialogContainer {
        width: 80%;
        max-width: 800px;
    }

    .rewardSelectDialog {
        .rewardSelectDialogHeader {
            border-bottom: 4px solid rgba(0, 0, 0, 0.05);
            padding: 24px 0 12px;

            i {
                left: 55px;
                font-size: 30px;
            }
        }

        .rewardSelectDialogBody {
            padding: 24px 24px 16px;
            min-height: 342px;

            .rewardCardList {
                padding: 0 32px;
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .rewardSelectDialog {
        .rewardSelectDialogBody {
            .rewardCardList {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .rewardCardOut {
                    width: calc(50% - 6px);

                    .rewardCard:hover {
                        border-color: #FFB600;
                    }
                }
            }
        }
    }
}