.singleOrderItem{
	position: relative;
	padding-left: 30px;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 10px;
	&::before{
		content:" ";
		position: absolute;
		height: 1px;
		left: 24px;
		right: 24px;
		top: 0;
		border-top: 1px solid #E8EAED;
	}
	.itemQty{
		width: 100%;
		span{
			background-color: #BDBDBD;
			color: #fff;
			line-height: 16px;
			padding: 0 5px;
			font-size: 12px;
			display: inline-block;
			height: 16px;
		}
	}
	.itemInfoLeft{
		flex: 1;
		.itemName{
			font-size: 14px;
			font-weight: 600;
			color: #000;
			line-height: 26px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
		.subInfo{
			font-size: 12px;
			color: #000;
			line-height: 18px;
		}
		.optionindex1{
			margin-left: 1em;
		}
		.saleItemSuboption{
			color: #999;
		}
		.qtyVoidToZero{
			text-decoration: line-through;
		}		
		
		.allowTogo{
			height: 20px;
			margin: 4px 0;
			.allowTogoInner{
				display: inline-block;
				height: 20px;
				padding: 1px 6px 1px 6px;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-radius: 6px;       
				cursor: pointer; 
				.allowTogoIcon{
					float: left;
					margin-right: 2px;
					width: 16px;
					height: 16px;
					font-size: 16px;
					line-height: 16px;
					color: rgba(0, 0, 0, 0.5);
				}
				span{
					float: left;
					font-size: 12px;
					font-weight: 500;
					line-height: 16px;
					color: rgba(0, 0, 0, 0.5);
				} 
			} 
		}
	}
	.itemInfoRight{
		padding-left: 10px;
		line-height: 26px;
		text-align: right;
		.itemPrice{
			color: #000;
			font-size: 13px;
		}
		.discountPrice{
			color: var(--theme-color);
		}
		.beforeDiscountPrice{
			color: #828282;
			font-size: 12px;
			text-decoration: line-through;
			margin-left: 6px;
		}
		.rightUp{
		  display: flex;
		  justify-content: flex-end;
		  align-items: center;
		}
		.spend{
			line-height: 18px;
			font-size: 12px;
			text-align: right;
		}
	}
	
	&.singleOrderItem1{
		padding-left: 56px;
		padding-top: 11px;
		padding-bottom: 30px;
		&::before{
			left: 16px;
			right: 16px;
		}
		.itemQty{
			position: absolute;
			top: 16px;
			left: 8px;
		}
		.itemInfoRight{
			position: absolute;
			bottom: 5px;
			left: 56px;
			padding-left: 0;
		}
		.itemStatus{
			height: 18px;
			line-height: 16px;
			text-align: center;
			padding: 0 8px;
			border-radius: 5px;
			font-size: 12px;
			margin-top: 5px;
			margin-right: 5px;
			&.ORDERED{
				color: #69C33F;
				background-color: rgba(105, 195, 63, 0.05);
			}
			&.SENT_TO_KITCHEN{
				color: rgba(33, 150, 243, 1);
				background-color: rgba(33, 150, 243, 0.05);
			}
			&.CANCELLED{
				color: rgba(85, 90, 95, 1);
				background-color: rgba(85, 90, 95, 0.05);
			}
		}
	}
}
@media screen and (min-width:769px) {
	.singleOrderItem{
		padding: 13px 0 13px 0;
		&::before{
			left: 0px;
			right: 0px;
		}
		.itemQty{
			width: 50px;
			padding-top: 5px;
		}
		.itemName{
			font-size: 16px;
		}
		.itemPrice{
			font-size: 16px;
		}
		.subInfo{
			font-size: 14px;
			line-height: 20px;
		}
	}
}