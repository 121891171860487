@import "../../constants/global.scss";

.givingListOutter {
    @include floatPanel;
}

.cardBx {
    @include cardBx;
    background: #f6fbfe;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 340px;
    max-height: 480px;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
}

.nocardBx {
    margin-top: 20px;
}

.cardSecTitle {
    font-size: 16px;
    font-weight: 600;
    margin: 5px 0;
}

.selectBx {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    font-size: 16px;
    position: relative;
}

.iconGift {
    height: 34px;
    line-height: 34px;
    font-size: 18px;
    color: var(--theme-text-color);
    padding: 0 7px;
    animation: shakeGift 0.5s infinite;
}

.customSelectBx {
    flex: 1 1 auto;
    width: 100%;
    height: 34px;
    line-height: 34px;
    padding-left: 10px;
    border: none;
    background: none;
    background-size: 12px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-left: 1px solid var(--border-color);
}

.selectIcon {
    width: 30px;
    height: 34px;
}

.optionBxBg {
    @include floatPanel;
    z-index: 1;
    background: none;
}

.optionBx {
    position: absolute;
    left: -1px;
    top: 34px;
    width: 100%;
    border: 1px solid var(--border-color);
    border-top: none;
    z-index: 2;
    max-height: 120px;
    overflow-y: auto;
    background: #f6fbfe;
}

.optionList {
    width: 100%;
    padding: 5px 10px;
    border-top: 1px solid var(--border-color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.givingListBx {
    height: calc(100% - 178px);
    max-height: 260px;
    overflow: auto;
}

.gotDeal {
    line-height: 36px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding: 5px 0 20px;
}

.givingItemList {
    margin-top: 10px;

    >label {
        span {
            font-size: 14px;
        }
    }
}

.promotionApplyBx {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-top: 20px;
    border: 1px solid #e0e0e0;
    cursor: pointer;

    span {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("../../images/Vector.png") center center no-repeat;
        background-size: 14px;

    }

    div {
        flex: 1;
        margin-left: 10px;
    }

    b {
        font-weight: normal;
        color: var(--theme-text-color);
    }
}

.givingFoot {
    display: flex;
}

.givingFoot>button {
    margin-top: 18px;
    padding: 0;

    &:nth-child(1) {
        margin-right: 10px;
        background-color: #fff;
        border: 1px solid #dedede;
        color: #333;
    }
}

@keyframes shakeGift {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(10deg)
    }

    75% {
        transform: rotate(-15deg);
    }

    85% {
        transform: rotate(15deg);
    }

    100% {
        transform: rotate(-15deg)
    }
}