.choosedReward.popup {
	.popHeader {
		height: 68px;
		display: flex;
		align-items: center;
		padding: 0 20px;
		position: relative;

		&::after {
			content: " ";
			position: absolute;
			bottom: 0;
			left: 34px;
			right: 34px;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.1);
		}

		span {
			padding-left: 12px;
			font-weight: 600;
			font-size: 18px;
			flex: 1;
		}

		.backIcon {
			display: flex;
			align-items: center;
		}

		.closeIcon {
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			i {
				font-size: 16px;
			}

			&:hover {
				i {
					font-size: 18px;
				}
			}
		}

		.popTitle {
			display: flex;
			align-items: center;

			.popName {
				word-break: break-all;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}

		.voucherLabel {
			flex: none;
			line-height: 16px;
			margin-left: 4px;
			padding-left: 0;

			span {
				font-size: 12px;
				padding: 0 8px;
				border-radius: 5px;
				background-color: #fff7e3;
				border: 1px solid #ffdf94;
				color: #e8a300;
			}
		}
	}

	.popMain {
		padding: 10px 20px 25px;

		.title {
			font-size: 16px;
			font-weight: 600;
			line-height: 30px;
			padding-top: 10px;
		}

		.option {
			position: relative;
			padding-left: 20px;
			margin-top: 20px;

			&::before {
				position: absolute;
				content: " ";
				width: 8px;
				height: 8px;
				border-radius: 4px;
				background-color: #FFB600;
				top: 7px;
				left: 3px;
			}

			color: #828282;
			font-size: 14px;
			line-height: 22px;

			&h3 {
				font-weight: 600;
				margin: 0 0 8px;
			}

			&p {
				margin: 0 0 6px;
				display: flex;

				span {
					padding-left: 5px;
				}
			}

			.option-desc {
				flex-direction: column;

				p {
					display: block;
				}
			}
		}
	}

	.popFooter {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		padding: 30px 24px 10px;

		i {
			color: #FFB600;
		}

		.points {
			display: flex;
			font-size: 18px;
			font-weight: 600;
			color: #120136;

			i {
				font-size: 22px;
			}

			.num {
				padding: 0 4px 0 6px;
			}
		}

		.desc {
			display: flex;
			align-items: center;
			font-size: 14px;
			padding: 10px 0;

			.pre {
				color: #828282;
				padding-right: 4px;
			}

			.num {
				padding: 0 3px;
			}
		}
	}

	&.earnPopup,
	&.rewardPopup {
		.popMain {
			max-height: calc(90vh - 250px);
			overflow: auto;
		}
	}

	&.historyPopup {
		.history {
			max-height: calc(100vh - 120px);
			overflow-y: auto;
			padding: 10px 20px 20px;

			.historyItem {
				display: flex;
				align-items: center;
				height: 80px;

				.left {
					width: 40px;
					height: 40px;
					border-radius: 20px;
					background-color: #FDF6DF;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #FFB600;

					i {
						font-size: 24px;
					}
				}

				.mid {
					flex: 1;
					padding-left: 12px;

					.top {
						font-size: 16px;
						font-weight: 600;
						color: #120136;
						height: 26px;
					}

					.bottom {
						font-size: 14px;
						color: #828282;
					}
				}

				.right {
					.top {
						display: flex;
						align-items: center;
						font-size: 16px;
						font-weight: 600;
						color: #120136;

						a {
							font-weight: normal;
							font-size: 18px;
						}

						span {
							padding-left: 4px;
						}

						i {
							color: #FFB600;
							font-size: 15px;
							margin-left: 5px;
						}
					}

					.bottom {
						text-align: right;
						font-size: 14px;
						color: #828282;
					}
				}
			}
		}
	}
}

@media screen and (min-width:481px) {
	.choosedReward.popup {
		min-width: 460px;
		max-width: 72vw;

		.popHeader {
			height: 60px;

			span {
				padding: 0;
			}

			.closeIcon {
				margin-right: -16px;
			}

			border-bottom: 1px solid rgba(0, 0, 0, 0.1);

			&::after {
				display: none;
			}
		}

		&.earnPopup,
		&.rewardPopup {
			.popMain {
				max-height: calc(80vh - 250px);
				overflow: auto;
			}
		}
	}

	.choosedReward.popup.historyPopup .history {
		max-height: 72vh;

		.historyItem {
			min-width: 440px;
			padding: 0 5px;
		}
	}
}