.verifyPhoneEmailBx {
    width: 100%;
    position: relative;

    .contactInfoBx {
        flex: 1;
    }

    .verifyDescription {
        font-size: 24px;
        font-weight: 700;
    }

    .verifyText {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;

        &>div {
            margin-top: 8px;
        }
    }

    .verifyIcon {
        margin-top: 32px;
        text-align: center;

        img {
            width: 100px;
            height: 100px;
        }
    }

    .verifyContactInfo {
        margin-top: 32px;

        .verifyContactList {
            margin-top: 16px;
        }

        span {
            position: relative;
            display: inline-block;
            padding: 6px 16px 6px 32px;
            line-height: 22px;
            background-color: var(--theme-opacity-color);
            border-radius: 20px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);

            &::before {
                content: " ";
                position: absolute;
                left: 16px;
                top: 13px;
                width: 8px;
                height: 8px;
                background: var(--theme-color);
                border-radius: 50%;
            }
        }
    }

    .verifyInputBx {
        height: 55px;
        position: relative;
        max-width: 342px;
        margin: 32px 0 0;

        .verifyInputFaker {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 55px;
            display: flex;
            justify-content: space-between;
            z-index: 9;

            .verifyInput {
                width: 50px;
                height: 55px;
                border-radius: 5px;
                background-color: #F2F2F2;
                text-align: center;
                line-height: 51px;
                font-size: 20px;
                position: relative;
                border: 2px solid #F2F2F2;
                font-weight: 600;

                &.active {
                    border-color: #333333;
                }

                &.beat::after {
                    position: absolute;
                    left: 18px;
                    top: -4px;
                    content: "|";
                    color: var(--theme-color);
                    animation: beatAni 1s infinite;
                }

                @keyframes beatAni {
                    0% {
                        opacity: 0;
                    }

                    50% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }
            }

            &.validCodeError {
                .verifyInput {
                    border-color: #F44336;
                    background-color: rgba(244, 67, 54, 0.04);
                }
            } 
        }

        .verifyInputAll {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            height: 55px;
            width: 100%;
            opacity: 0;
            z-index: 10;
        }

        .verifyInput input::-webkit-inner-spin-button,
        .verifyInput input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .verifyErrorText {
        padding-top: 8px;
        font-size: 14px;
        color: rgba(244, 67, 54, 1);
    }

    .resendBx {
        margin-top: 32px;
        font-size: 16px;

        .notReceiveText {
            margin-right: 4px;
        }

        .resendBtn {
            font-weight: 700;
            color: rgba(0, 68, 255, 1);
            cursor: pointer;

            &.disabled {
                color: #BDBDBD;
            }

            span {
                text-decoration: underline;
            }
        }
    }

    .mainColorText {
        color: var(--theme-text-color);
        cursor: pointer;
    }

    .btnCon {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 16px;

        .submitBtn {
            width: 100%;
            margin-top: 16px;
        }
    }

    .btn {
        height: 36px;
        line-height: 36px;
        border-radius: 20px;
        border: 1px solid var(--theme-color);
        color: var(--theme-text-color);
        cursor: pointer;
        font-size: 16px;
        padding: 0 20px;
        margin-top: 15px;
        min-width: 200px;
        text-align: center;

        &.disabled {
            color: #ababab;
            border-color: #dcdcdc;
            background-color: #f5f5f5;
            pointer-events: none;
            cursor: not-allowed;
        }
    }
}

@media only screen and (max-width: 480px) {
    .verifyPhoneEmailBx {
        &.mergedEmailBx {
            display: flex;
            flex-direction: column;
            height: 100%;
            height: calc(100% - 42px);
        }
    }
}