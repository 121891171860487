@import "../../constants/global.scss";

.customPromptOuter {
    @include floatPanel;
    display: flex;
    align-items: center;
    z-index: 66;
}

.customPromptInner {
    @include floatPanelInner;
    width: calc(100% - 40px);
    margin: 0 20px;
    padding: 30px 20px;
    z-index: 66;
}

@media only screen and (min-width: 481px) {
    .customPromptInner {
        @include floatPanelInner;
        z-index: 66;
        left: calc(50% - 200px);
        width: 400px;
        top: auto;
        bottom: auto;
    }
}

.customPromptContent {
    font-size: 18px;
}

.promptContentBx,
.promptContentLowBx {
    display: flex;
    justify-content: center;
    align-items: center;
}

.promptContentBx {
    height: 100%;
}

.promptContentDetail {
    width: 100%;
}

.promptContentLowBx {
    height: calc(100% - 40px);
}

.EventHandlerBtn {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.approveBtn {
    font-size: 14px;
    width: 40%;
    height: 44px;
    line-height: 44px;
    background-color: var(--theme-color);
    text-align: center;
    border-radius: 10px;
    color: var(--color-btn-text);
    cursor: pointer;
}

.approveBgWhite {
    background-color: var(--white-background-color);
    border: 1px solid var(--border-color);
    color: #555;
}

.promptTitle {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.promptText {
    font-size: 14px;
    word-break: break-word;
}

.cardType {
    display: inline-block;
    text-align: center;
    width: 53px;
    height: 32px;
    line-height: 32px;
    background: lightgray;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
}

.promptContent {
    display: inline-block;
}

.expireDate {
    margin-left: 20px;
}