.toastPanel {
    position: fixed;
    top: 48px;
    opacity: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    transition: all 0.25s;
    transform: translate3d(0, -120px, 0);
    z-index: 121;
    padding: 0 24px;

    .toastBar {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        padding: 12px 16px;
        border-radius: 26px;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
        background-color: #fff;
        max-width: 780px;

        svg,
        img {
            margin-right: 8px;
            width: 24px;
            height: 24px;
        }

        a.text {
            flex: 1;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.8);
            cursor: default;
            padding-right: 5px;
        }
    }

    &.show {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}