.timeSelectComponent {
	.timeOption {
		display: flex;
		align-items: center;
		height: 28px;

		.radio {
			font-size: 20px;
			color: var(--theme-color);
			cursor: pointer;
		}

		.label {
			font-size: 14px;
			color: #333333;
			padding-left: 10px;
			font-weight: 600;
			cursor: pointer;
		}

		&.grey {

			.radio,
			.label {
				color: #E0E0E0;
				cursor: not-allowed;
			}
		}
	}

	.timeSelect {
		margin-top: 6px;
	}
}