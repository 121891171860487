.ui_dialog_wrap{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 200;
	background-color: rgba(0,0,0,0.6);
	transition: opacity 0.25s;
	opacity: 0;
	.ui_dialog{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) scale(0.6,0.6);
		background-color: #fff;
		border-radius: 8px;
		transition: transform 0.2s;
		font-size: 14px;
		padding-bottom: 15px;
		width: 92%;
		max-width: 420px;
		.ui_dialog_close{
			position: absolute;
			right: 0;
			top: 0;
			width: 52px;
			height: 56px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img{
				width: 20px;
				height: 20px;
				transition: all 0.1s;
				opacity: 0.9;
			}
			&:hover{
				img{
					opacity: 1;
					width: 22px;
					height: 22px;
				}
			}
		}
		.ui_dialog_title{
			font-size: 17px;
			line-height: 30px;
			font-weight: bold;
			padding: 15px 60px 15px 20px;
			font-family: 'OpenSans-SemiBold';
			letter-spacing: -0.06em;
		}
		.ui_dialog_content{
			padding: 10px 20px 15px;
			font-size: 15px;
			line-height: 22px;
			p{
				margin: 5px 0;
			}
		}
		.ui_dialog_foot{
			height: 50px;
			padding: 0 22px;
			display: flex;
			align-items: center;
			&.between{
				justify-content: space-between;
			}
			.ui_dialog_btn{
				height: 34px;
				border-radius: 17px;
				text-align: center;
				cursor: pointer;
				transition: all 0.2s;
				font-weight: bold;
				font-size: 15px;
				width: 46%;
				font-family: 'OpenSans-SemiBold';
				letter-spacing: -0.06em;
				&.cancel{
					line-height: 32px;
					border: 1px solid #ccc;
					color: #333;
				}
				&.ok{
					line-height: 34px;
					background-color: var(--theme-color);
					color: var(--color-btn-text);
				}
				&.single{
					width: 200px;
					margin: 0 auto;
				}
				&:hover{
					box-shadow: 0 0 5px rgba(0,0,0,0.2);
				}
			}
		}
		
		
	}
	&.show{
		opacity: 1;
		.ui_dialog{
			transform: translate(-50%,-50%) scale(1,1);
		}
	}
	&.hide{
		opacity: 0;
	}
}

@media screen and (max-width:480px){
	.ui_dialog_wrap{
		.ui_dialog.mobileBottom{
			top: unset;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			border-radius: 16px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			transform: translate(0,100%) scale(1,1);
			padding-bottom: 30px;
		}
		&.show{
			.ui_dialog.mobileBottom{
				transform: translate(0,0) scale(1,1);
			}
		}
	}
}

@media screen and (min-width: 481px){
	.ui_dialog_wrap{
		.ui_dialog{
			.ui_dialog_title{
				font-size: 18px;
			}
			.ui_dialog_content{
				font-size: 16px;
				line-height: 25px;
			}
			.ui_dialog_foot{
				height: 60px;
				.ui_dialog_btn{
					height: 38px;
					font-size: 16px;
					&.cancel{
						line-height: 36px;
					}
					&.ok{
						line-height: 38px;
					}
				}
			}
			
		}
	}

}