.splitTipCon{
	margin-top: 20px;
	background-color: #fff;
	border-radius: 5px;
	padding: 14px 5px 14px 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	img{
		width: 28px;
		height: 28px;
		margin-right: 12px;
	}
}