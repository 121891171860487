@import "../../constants/global.scss";

.promotionDetailsOuter {
    @include floatPanel;
    z-index: 62;
    opacity: 0;
    transition: opacity 0.2s;

    .promotionDetailsInner {
        transform: scale(0.7, 0.7) translateY(-50%);
        transition: transform 0.2s;
    }

    &.show {
        opacity: 1;

        .promotionDetailsInner {
            transform: scale(1, 1) translateY(-50%);
        }
    }
}

.promotionDetailsInner {
    @include floatPanelInner;
    z-index: 62;
    height: auto;
    max-height: 600px;
    padding: 20px 24px;
    width: 480px;
    left: calc(50% - 240px);
    right: unset;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px 5px 0 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
}

.promTitle {
    line-height: 20px;
    padding: 2px 0;
    margin: 0;
    font-size: 17px;
}

.promMain {
    flex: 1;
    overflow: auto;
}

.promCont {
    margin: 12px 0 0;

    dt {
        line-height: 22px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    dd {
        margin-left: 0;
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        line-height: 18px;
        padding-top: 4px;
        padding-bottom: 4px;

        .text {
            flex: 1;
        }
    }

    .circle {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-top: 9px;
        border-radius: 4px;
        background: #4f4f4f;
        margin-right: 5px;
    }

    .isincluded {
        margin-left: 10px;
        margin-right: 4px;
    }
}

.promFoot {
    margin: 24px 22px 10px;
}

@media (max-width:768px) {
    .promotionDetailsInner {
        width: 90%;
        max-height: 480px;
        left: 5%;
    }
}