.loadingPanelOuter {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 200;
}

.loadingInner {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    margin-left: -30px;
}

.loading-box {
    *display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    background: rgba(0, 0, 0, .4);
    z-index: 201;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: -60px;
    margin-left: -60px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.loadingLabel {
    margin-top: 16px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
}