.ui_button {
    padding: 0;
    border: none;
	outline: none;
    height: 56px;
	width: 100%;
    color: var(--color-btn-text);
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
	position: relative;
	
	&::before{
		display: block;
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0;
		bottom: 0;
		border-radius: 10px;
		transition: background-color 0.2s ;
	}
    
    &.primary{
        background-color: var(--theme-color);
    }
	
	&.white {
	    background: rgba(0, 0, 0, 0.03);
		color: rgba(0,0,0,0.8);
		transition: box-shadow 0.2s;
	}
	
	&.danger{
		color: #fff;
		background: rgb(244, 67, 54);
	}
	
	&.disabled{
		opacity: 0.45;
		cursor: not-allowed;
	}
	
	&:hover {
	    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	}
	
	&:hover::before{
		background: rgba(255, 255, 255, 0.075);
	}
	
	&:active::before {
	    background: rgba(0, 0, 0, 0.075);
	}
	
	i{
		font-weight: normal;
		font-size: 24px;
		margin-right: 8px;
	}
}

.ui_button_loading_cover {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    z-index: 10000;
}

@media screen and (min-width:768px) {
    .ui_button{
        height: 44px;
    }
}