.rateButton {
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 44px;
    color: rgba(0, 0, 0, 0.80);
    text-align: center;
    font-size: 14px;
    font-weight: 500;

    svg {
        margin: 0 !important;
    }

    span {
        padding-right: 12px;
    }

    .iconLeft {
        margin-right: 12px;
        width: 36px;
        height: 36px;
    }

    .iconRight {
        width: 20px;
        height: 20px;
    }
}

.rateBtnLoadingWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 99;
}

.rateDialogWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;

    .rateDialog {
        width: 90%;
        max-width: 800px;
        height: 90%;
        max-height: 600px;
        background-color: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .closeIcon {
            position: absolute;
            z-index: 100;
            top: 12px;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            i {
                font-size: 32px;
            }

            &.leftIcon {
                left: 12px;
                display: none;
            }

            &.rightIcon {
                right: 12px;
            }
        }

        .iframeLoadingLine {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 3px;
            z-index: 100;

            .iframeLoadingLineInner {
                width: 10px;
                height: 3px;
                background-color: #0590fa;
                transition: width 10s;

                &.start {
                    width: 96%;
                }

                &.done {
                    transition: width 0.4s linear;
                    width: 100%;
                }

                &.hide {
                    display: none;
                }
            }
        }

        >iframe {
            width: 100%;
            height: 100%;
            background-color: #fff;
            overflow: hidden;
            border: none;
            outline: none;
        }
    }
}

@media screen and (min-width:480px) {
    .rateButton {
        &.inOrderDetail {
            width: 45%;
            max-width: 342px;
            min-width: 320px;
        }

        .iconRight {
            display: none;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &:active {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

@media screen and (max-width:480px) {
    .rateButton {
        height: 56px;

        &.inOrderDetail {
            background-color: #fff;

            &:active {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        &:active {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .rateDialogWrap .rateDialog {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0;

        .closeIcon.leftIcon {
            display: flex;
            top: 8px;
        }

        .closeIcon.rightIcon {
            display: none;
        }
    }
}