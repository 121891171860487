@import "../../constants/global.scss";

.promotionDetailsOuter {
    @include floatPanel;
    z-index: 62;
}

.promotionDetailsInner {
    @include floatPanelInner;
    z-index: 62;
    width: 400px;
    height: 300px;
    padding: 20px 24px;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -200px;
    border-radius: 5px 5px 0 0;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.promTitle {
    line-height: 20px;
    padding: 2px 0;
    margin: 0;
    font-size: 17px;
}

.promMain {
    flex: 1;
    overflow: auto;
}

.promCont {
    margin: 12px 0 0;

    dt {
        line-height: 22px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    dd {
        margin-left: 0;
        display: flex;
        align-items: flex-start;
        font-size: 16px;
        line-height: 22px;
    }

    .contMarginTop {
        margin-top: 10px;
    }

    .circle {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-top: 9px;
        border-radius: 4px;
        background: #4f4f4f;
        margin-right: 5px;
    }
}

.promFoot {
    display: flex;
    margin: 10px 5px;
}

.promFoot>div {
    margin: 5px;
}

@media (max-width:480px) {
    .promotionDetailsInner {
        width: auto;
        height: auto;
        max-height: 80%;
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        margin-top: 0;
        margin-left: 0;
    }
}