.surveryTermsWrap {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.65);
	opacity: 0;
	transition: all 0.3s;

	.surveyTerms {
		border-radius: 10px;
		background-color: #fff;
		overflow: hidden;
		position: absolute;
		z-index: 9;
		top: 80%;
		left: 50%;
		transition: all 0.3s;
		transform: translate(-50%, -50%);
		width: calc(100% - 40px);
		height: calc(100% - 60px);
		display: flex;
		flex-direction: column;

		.header {
			height: 48px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			display: flex;
			align-items: center;
			font-size: 16px;
			padding-left: 12px;

			.title {
				flex: 1;
			}

			.closeBtn {
				width: 48px;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}

		.con {
			height: 0;
			flex: 1;
			overflow: auto;
			font-size: 14px;
			line-height: 24px;
			padding: 12px;
			color: #565656;

			a {
				word-break: break-all;
			}
		}
	}

	&.show {
		opacity: 1;

		.surveyTerms {
			top: 50%;
		}
	}
}

@media screen and (min-width:769px) {
	.surveryTermsWrap {
		.surveyTerms {
			width: 780px;
			height: calc(100% - 180px);
		}
	}
}