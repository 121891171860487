.ui_input_con {
    margin-bottom: 16px;

    .ui_input_row {
        display: flex;
        align-items: center;
        background-color: var(--neutral-colors-g-85, rgba(0, 0, 0, 0.05));
        border-radius: 10px;
        height: 56px;
        border: 2px solid #f2f2f2;
        overflow: hidden;

        .ui_input_row_sub {
            flex: 1;
            position: relative;
        }

        &.focused {
            background-color: #fff;
            border-color: var(--neutral-colors-g-180, rgba(0, 0, 0, 0.80));
        }

        &.err {
            border-color: var(--feedback-colors-error-t-1100, #F44336);
            background-color: rgba(244, 67, 54, 0.04);
        }
    }

    .country_code {
        height: 16px;
        line-height: 16px;
        width: 52px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        border-right: 2px solid var(--neutral-colors-g-520, rgba(0, 0, 0, 0.20));
    }

    .ui_input {
        height: 52px;
        line-height: 52px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        padding-left: 16px;
        padding-right: 28px;
        border: none;
        background: none;
        outline: none;
        width: 100%;

        &.disabled {
            color: var(--neutral-colors-g-350, rgba(0, 0, 0, 0.50));
        }

        &[type="password"] {
            font-size: 22px;
            letter-spacing: 1px;
        }
    }

    .clear_icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0px;
        width: 32px;
        opacity: 1;
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
            font-size: 24px;
        }

        &.hide {
            opacity: 0;
        }
    }

    .ui_input_err {
        margin-top: 8px;
        line-height: 20px;
        color: var(--feedback-colors-error-t-1100, #F44336);
        font-size: 14px;
    }
}

.ui_input_placeholder,
input.ui_input::placeholder,
input.ui_input::-webkit-input-placeholder {
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.2);
}

@media screen and (min-width:800px) {
    .ui_input_con {
        .ui_input_row {
            height: 44px;
        }

        .ui_input {
            height: 40px;
            line-height: 40px;
        }

        .country_code {
            top: 13px;
        }
    }
}