@import "../../constants/global.scss";

.orderItemBx{
  position: relative;
  display: flex;
  padding: 15px 0;
  overflow: hidden;
  border-bottom: 1px solid #f0f0f0;
}
.hasRemove{
  background: #f2f2f2;
}
.orderItemNameBx{
  word-break: break-all;
  flex: 1 1 10px;
}
.orderItemName,.optionListBx{
  width: 100%;
  line-height: 20px;
}
.optionListBx{
  margin-top: 2px;
}
.itemOption{
  color: #333;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
}
.optionindex1{
  margin-left: 1em;
}
.saleItemSuboption{
  color: #999;
}
.orderItemRight{
  .rightUp{
    display: flex;
    justify-content: flex-end
  }
  .spend{
    text-align: right;
  }
}
.orderItemPrice, .orderItemQtyBx{
  flex:0 0 auto;
  text-align: right;
}
.orderItemQtyBx{
  margin-right: 10px;
  align-items: flex-start;
  display: flex;
}
.orderItemPrice{
  padding-left: 10px;
  line-height: 20px;
}
.spend{
  margin-top: 2px;
  font-size: 12px;
}
.removeQty{
  position: absolute;
  right: -120px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: var(--theme-color);
  color: #fff;
  font-size: 14px;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
  cursor: pointer;
  z-index: 1;
  font-size: 12px;
}
.hasRemove .removeQty{
  right: 0;
}
.hasRemove .orderItemQtyBx{
  // visibility: hidden;
}
.orderUnavailable{
  display: inline-block;
  color:#ff4425;
}
.orderClickCursor{
  cursor: pointer;
}
.categoryName{
  text-align: center;
  color: #f67100;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.categoryIcon{
  width: 0.24rem;
  height: 0.24rem;
  display: inline-block;
  background: #fca71d;
  transform: scale(0.5,1) rotate(45deg) ;
  margin: 0 0.36rem;
}
.categoryItemsOut:last-child{
  border-bottom:0;
  margin-bottom: 0;
}
.categoryItemsOut{
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
  padding-left: 0.4rem;
  overflow: hidden;
  .categoryItemNameOut{
    flex: 1;
  }
  .categoryItemName{
    margin-bottom: 0.3rem;
    font-size: 14px;
  }
  .itemOption{
    font-size: 12px;
  }
  .orderItemPrice{
    text-align: left;
    padding: 0;
    margin-top: 0.3rem;
    font-size: 14px;
  }
}
.beforeDiscountPrice{
  text-decoration: line-through;
  text-decoration-color: var(--theme-color);
  margin-right: 5px;
  color: var(--theme-color);
}
.cardBxSDI{
  @include cardBxSDI;  
  .mrBut{
    flex:1 1 auto;
    font-size: 15px;  
    display:flex;
    justify-content: flex-end;    
    .quantity{
      padding: 0 0.3rem;
    }
  }
  .mrBut > span{  
    display:inline-block
  }
  .icon{
    font-size: 0.88rem;
    color: #0391e2;
  }
  .iconGrey{
    color:#c1c1c1;
  }
}
@media only screen and (min-width: 769px){
  .removeQty{
    position: relative;
    right: 0;
    background: none;
    color:var(--theme-text-color);
    text-decoration: underline;
    padding: 0 0 0 10px;
    line-height: 20px;
	font-size: 12px;
  }
}

