.ui_bubble{
	
}

.ui_bubble_wrap{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255,255,255,0.2);
	z-index: 190;
	.ui_bubble_triangle{
		position: fixed;
		width: 8px;
		height: 8px;
		background-color: #333;
		transform: rotate(45deg);
		z-index: 191;
	}
	.ui_bubble_text{
		position: fixed;
		background-color: #181919;
		width: 240px;
		border-radius: 6px;
		padding: 10px 8px 11px 14px;
		line-height: 18px;
		font-size: 13px;
		color: #fff;
		z-index: 192;
		box-shadow: 0 4px 7px rgba(0,0,0,0.2);
	}
}
